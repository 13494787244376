
//extend classes


// use:  .rounded-30
$radius: 10,20,30,40,50;
@each $size in $radius {
  .rounded-#{$size}{
    @include rounded(#{$size}px);
  }
}


// use:  .gutter-30
// use:  .gutter-sm-30
// use:  .gutter-md-30
// use:  .gutter-lg-30
// use:  .gutter-xl-30

$gutter: 14,24,30,40,70;
@each $size in $gutter {
  .gutter-#{$size} {
    margin-right: -#{$size/2}px;
    margin-left: -#{$size/2}px;

    > .col,
    > [class*="col-"] {
      padding-right: #{$size/2}px;
      padding-left: #{$size/2}px;
    }
  }
}

@include media-breakpoint-up(sm) {
  @each $size in $gutter {
    .gutter-sm-#{$size} {
      margin-right: -#{$size/2}px;
      margin-left: -#{$size/2}px;

      > .col,
      > [class*="col-"] {
        padding-right: #{$size/2}px;
        padding-left: #{$size/2}px;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  @each $size in $gutter {
    .gutter-md-#{$size} {
      margin-right: -#{$size/2}px;
      margin-left: -#{$size/2}px;

      > .col,
      > [class*="col-"] {
        padding-right: #{$size/2}px;
        padding-left: #{$size/2}px;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  @each $size in $gutter {
    .gutter-lg-#{$size} {
      margin-right: -#{$size/2}px;
      margin-left: -#{$size/2}px;

      > .col,
      > [class*="col-"] {
        padding-right: #{$size/2}px;
        padding-left: #{$size/2}px;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  @each $size in $gutter {
    .gutter-xl-#{$size} {
      margin-right: -#{$size/2}px;
      margin-left: -#{$size/2}px;

      > .col,
      > [class*="col-"] {
        padding-right: #{$size/2}px;
        padding-left: #{$size/2}px;
      }
    }
  }
}


