



//map merge

$theme-colors: (
  "gray": #e4e4e4,
  "primary": #0790c9,
  "warning": #e1aa04,
);

$spacer: 1rem;

$spacers: (
        0: 0,
        1: ($spacer * 0.25),
        2: ($spacer * 0.5),
        3: ($spacer * 0.75),
        4:  $spacer,
        5: ($spacer * 1.25),
        6: ($spacer * 1.75),
        7: ($spacer * 2.5),
        8: ($spacer * 3.5),
);

//$sizes: ();

//bootstrap variables
@import "../../node_modules/bootstrap/scss/variables";

//theme variables

$blue:#1d5dd2;
$primary:#0790c9;


// Body
$body-bg: #fff;
$body-color: #444;

// Grid
$grid-columns:      12;
$grid-gutter-width: 30px;


// Links
//
$link-color:                  theme-color("primary");
$link-decoration:             none;
$link-hover-color:            darken($link-color, 15%);
$link-hover-decoration:       underline;

// Headings
//
$font-weight-lighter:         200;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semi-bold:       600;
$font-weight-bold:            700;
$font-weight-bolder:          800;

$headings-font-family:        null;
$headings-font-weight:        600;
$headings-line-height:        1.2;


// Tables
//
$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .3rem;
$table-head-bg:               $gray-200;
$table-head-color:            $gray-700;

// Paragraph

$paragraph-margin-bottom:     1rem;

// Buttons + Forms

$input-btn-font-family:       null;

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

// Forms

$label-margin-bottom:         .5rem;

$input-padding-y:             $input-btn-padding-y;
$input-padding-x:             $input-btn-padding-x;
$input-font-size:             $input-btn-font-size;

$input-padding-y-sm:          $input-btn-padding-y-sm;
$input-padding-x-sm:          $input-btn-padding-x-sm;
$input-font-size-sm:          $input-btn-font-size-sm;

$input-bg:                    $white;
$input-disabled-bg:           $gray-200;
$input-color:                 $gray-700;
$input-border-color:          $gray-400;
$input-border-radius:         $border-radius;
$input-placeholder-color:     $gray-600;
$input-plaintext-color:       $body-color;


// Badges

$badge-font-size:             75%;
$badge-font-weight:           $font-weight-bold;
$badge-padding-y:             0.25em;
$badge-padding-x:             0.4em;
$badge-border-radius:         $border-radius;


// Modals


$modal-inner-padding:         1rem;
$modal-dialog-margin:         0.5rem;
$modal-content-border-radius: $border-radius-lg;

$modal-xl:                    1140px;
$modal-lg:                    800px;
$modal-md:                    500px;
$modal-sm:                    300px;


// Progress bars

$progress-height:             1rem;
$progress-font-size:          $font-size-base * .75;
$progress-bg:                 $gray-200;
$progress-border-radius:      $border-radius;
$progress-box-shadow:         inset 0 .1rem .1rem rgba($black, .1);



// Breadcrumbs
$breadcrumb-font-size:        null;
$breadcrumb-padding-y:        .75rem;
$breadcrumb-padding-x:        1rem;
$breadcrumb-item-padding:     .5rem;
$breadcrumb-margin-bottom:    1rem;
$breadcrumb-bg:               $gray-200;
$breadcrumb-divider-color:    $gray-600;
$breadcrumb-active-color:     $gray-600;
$breadcrumb-divider:          quote("/");
$breadcrumb-border-radius:    $border-radius;


// Close

$close-font-size:             $font-size-base * 1.5;
$close-font-weight:           $font-weight-bold;
$close-color:                 $black;
$close-text-shadow:           0 1px 0 $white;



// Printing

$print-page-size:             a4;
$print-body-min-width:        map-get($grid-breakpoints, "lg");



