
//fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

//$path:'../fonts';
//
//@font-face {
//  font-family: 'MyWebFont';
//  src: url('#{$path}/webfont.eot'); /* IE9 Compat Modes */
//  src: url('#{$path}/webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$path}/webfont.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$path}/webfont.woff') format('woff'), /* Pretty Modern Browsers */
//       url('#{$path}/webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//       url('#{$path}/webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
//}