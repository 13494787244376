
* {
  outline: none !important;
}


@import 'modules/fonts';
@import 'modules/function';
@import 'modules/variables';
@import 'modules/mixins';

body {
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}

@import 'modules/extend';
@import 'modules/utilities';

.transition{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.transitionBorder{
  -webkit-transition: border-color 0.15s;
  -moz-transition: border-color 0.15s;
  -ms-transition: border-color 0.15s;
  -o-transition: border-color 0.15s;
  transition: border-color 0.15s;
}
.block {
  float: left;
  width: 100%;
}
.inlineblock{
  display: inline-block;
  width: 100%;
}

/* clear */
.clear:before,
.clear:after {
  content: ' ';
  display: table;
}
.clear:after {
  clear: both;
}

.flex-grow-0{
  min-width: initial;
}
//colors




//
//::-moz-selection { /* Code for Firefox */
//  background: rgba($primary,0.7);
//  color:#fff;
//}
//
//::selection {
//  background: rgba($primary,0.7);
//  color:#fff;
//}



img {
  max-width: 100%;
  @extend %user-select-none;
}
a {
  text-decoration: none;
  @extend .transition;
}
a:hover {
  text-decoration: none;
}
a:focus {
  outline: 0;
}
a:hover,
a:active {
  outline: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: $font-weight-semi-bold;
  color: #000;
  margin: 0 0 15px;
}

h1,.h1{
  font-size: rem(22);
}
h2,.h2{
  font-size: rem(20);
}
h3,.h3{
  font-size: rem(18);
}
h4,.h4{
  font-size: rem(17);
}
h5,.h5{
  font-size: rem(16);
}
h6,.h6{
  font-size: rem(14);
}

a{
 color:#333;
}

p {
  color: #58585a;
  font-size: rem(14);
}
b, strong{
  font-weight: $font-weight-semi-bold;
}
.fw-500{
  font-weight: $font-weight-medium;
}
.flex-grow-0{
  label{
    white-space: nowrap;
  }
}

.date{
  font-weight: $font-weight-medium;
  color: #b3b3b3;
}

.btn{
  padding: 10px 30px;
  font-size: rem(15);
  font-weight: 600;
  border:none;
  @extend .transition;
  &.btn-sm{
    padding: 7px 16px;
    font-size: rem(13);
  }
  &.btn-xs{
    font-size: rem(12);
    padding: 5px 13px;
    font-weight: 500;
  }
  &[class*="btn-outline"]{
    border-width: 1px;
    border-style: solid;
  }
  [class*="icon-trash"]{
    color: red;
    font-size: rem(23);
    margin: -4px 10px -4px -4px;
    display: inline-block;
    vertical-align: sub;
  }
}



.badge-tag{
  font-size: rem(12);
  font-weight: 400;
  padding: 6px 9px;
  margin-right: 3px;
  border-radius: 0;
  margin-bottom: 5px;
}


//login pages

.login-page{
  background: #F7F7F6;
  height: 100vh;
  overflow: auto;
  .content{
    margin: 0 auto;
    width: 100%;
    max-width: 540px;
    padding: 70px 0;
    .logo{
      text-align: center;
      margin: 0 0 50px;
    }
    .card{
      border: none;
      border-radius: 15px;
      box-shadow: 11px 14px 16px -6px rgba(0, 0, 0, 0.07);

      .card-body{
        padding: 50px 70px;
        .head{
          margin: 0 0 50px;
          text-align: center;
          h3{
            font-size: rem(22);
          }
        }
        h4{
          font-size: rem(16);
          margin: 20px 0;
        }
        .form-group{
          margin: 0 0 15px;
          .form-control{
            padding: 10px 20px;
            height: 46px;
            font-size: rem(15);
            border: 1px solid #dfdfdf;
            border-radius: 5px;

            &:focus{
              box-shadow: 0 0 0;
              border-color: $primary;
            }
          }

          select.form-control{
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent url(../images/select-icon.png) no-repeat scroll right center;
          }
        }
        .btn{
          width: 100%;
          padding: 14px 30px;
          border-radius: 4px;
          margin-top: 10px;
        }
        .back-link{
          margin: 50px auto 0;
          text-align: center;
          display: table;
          font-weight: $font-weight-medium;
        }
      }
    }
    .info{
      margin-top: 40px;
      font-weight: $font-weight-medium;
      a{
        margin-left: 20px;
      }
    }
  }
}
.header{
  padding: 12px 20px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }
  .logo{
    flex: 0 0 190px;
    img{
      width: 100%;
      max-width: 164px;
      margin: -6px 0;
    }
  }
  .title{
    h3{
      font-size: 22px;
      color: #383838;
      font-weight: $font-weight-medium;
    }
  }
  .right-buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .dropdown{
      margin-left: 30px;
      .dropdown-toggle{
        font-size: rem(15);
        font-weight: $font-weight-medium;
        display: inline-block;
        cursor: pointer;
        color:$primary;
        &:after{
          border-top: 0.4em solid;
          transform: translate(0px, 2px);
        }
        @extend %user-select-none;

        &:after{
          display: none;
        }

        i{
          font-size: rem(26);
          margin-top: 3px;
          display: inline-block;
        }
        .badge{
          padding: 4px;
          border-radius: 50%;
          position: absolute;
          right: -1px;
          top: 4px;
          display: inline-block;
          border: 2px solid #fff;
        }
      }
      .dropdown-menu{
        padding: 10px 20px;
        margin-top: 12px;
        box-shadow: 0 0 14px -2px rgba(0, 0, 0, 0.1);
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        min-width: 170px;
        .dropdown-item{
          font-size: rem(14);
          padding: 0;
          color:#333;
          margin-bottom: 2px;
          font-weight: $font-weight-medium;
          &:hover,
          &:focus{
            color:#333;
            background-color: transparent;
          }
          .alert{
            width: 260px;
            white-space: initial;
            margin: -2px -22px 0;
            border: none;
            border-radius: 0;
            padding-right: 30px;
            background: transparent;
            border-bottom: 1px solid #ddd;
            font-size: rem(13);
            line-height: 1.4;
            .close{
              right: 10px;
              top: 6px;
              position: absolute;
              padding: 0 2px;
              font-size: rem(26);
              line-height: 0.9;
              font-weight: $font-weight-medium;
            }
            .clearfix{
              font-size: rem(12);
              margin-top: -4px;
              margin-right: -16px;
            }
            &:hover{
              background-color: #ececec;
            }
          }
          &:last-child{
            .alert{
              border-bottom: 0 none;
            }
          }
        }
      }
    }
  }
}

.breadcrumb{
  padding: 10px 10px 10px 210px;
  margin: 0;
  background: transparent;
  .breadcrumb-item{
    font-weight: $font-weight-medium;
    font-size: rem(13);
    a{
      color: #000;
    }
    &::before{
      content: ">";
    }
    &:first-child::before{
      content: "";
    }
  }
}
.tablist{
  margin: 10px 0 0;
  .nav-item{
    margin-bottom: 30px;
    .nav-link{
      background: #f7f7f7;
      padding: 13px 31px;
      margin-right: 30px;
      font-size: rem(14);
      color: #000;
      border: 1px solid #c4c4c4;
      font-weight: $font-weight-medium;

      &.active{
        border: 1px solid $secondary;
        background: $secondary;
        color: #fff;
      }
    }
  }
}

.nav-tabs{
  border-bottom: 1px solid #b6b6b6;
  .nav-item{
    .nav-link{
      border: 1px solid #b6b6b6;
      border-radius: 0;
      font-size: rem(15);
      font-weight: $font-weight-medium;
      padding: 7px 30px 5px;
      color: #000;
      &.active{
        background: $secondary;
        color: #fff;
        border-color: $secondary;
      }
    }
  }
}
.popover{
  border:none;
  font-family: 'Montserrat', sans-serif;
  .popover-body{
    padding: 20px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
    @extend %rounded-10;
    .profile{
      font-size: rem(15);
      font-weight: $font-weight-semi-bold;
      margin-bottom: 10px;
      display: inline-block;
      img{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        object-fit: contain;
        margin: -3px 4px -3px 0;
        vertical-align: top;
      }
      &,&:hover{
        text-decoration: none;
      }
    }

    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        font-size: rem(14);
        margin-bottom: 2px;
        font-weight: $font-weight-medium ;
        b{
          font-weight: $font-weight-semi-bold ;
          margin-left: 5px;
        }
      }
    }
  }
}


.main-content{
  display: flex;
  &>*{
    flex-grow: 1;
    flex-basis: 0;
  }
  .sidebar{
    flex: 0 0 210px;
    padding: 0;
    background: #fff;
    z-index: 99;
    position: relative;
    transition: transform 0.3s;

    .avatar{
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 30px;
      margin-top: -18px;
      img{
        height: 40px;
        width: 40px;
        object-fit: cover;
        flex: 0 0 40px;
        margin-right: 12px;
        background: #f2f4f4;
      }
      span{
        font-weight: $font-weight-semi-bold;
        font-size: rem(15);
      }
    }
    .wraper{
      height: 100%;
      position: relative;
    }
    .scroll{
      background: rgba(0,0,0, 0.1);
      position: absolute;
      right: 0;
      width: 4px;
      z-index: 999;
      transition: height 0.3s;
      -webkit-transition: height 0.3s;
      -moz-transition: height 0.3s;
    }
    @-moz-document url-prefix() {
      .nav {
        direction: rtl;
        margin-left: -17px;
        width: calc(100% + 17px);
      }
    }
    @supports (-ms-accelerator:true) {
      .nav {
        direction: rtl;
        margin-left: -17px;
        width: calc(100% + 17px);
      }
    }

    @supports (-ms-ime-align:auto) {
      .nav {
        direction: rtl;
        margin-left: -17px;
        width: calc(100% + 17px);
      }
    }

    .nav{
      height: 100%;
      overflow-y: scroll;
      min-width: 100%;
      padding:0;
      position: relative;
      display: inline-block;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0;
      }
      .nav-item{
        direction: ltr;
        .nav-link{
          padding: 12px 20px;
          color: #000;
          font-weight: $font-weight-medium;
          font-size: rem(15);
          i{
            font-size: rem(24);
            vertical-align: middle;
            color: #bdbdbd;
            margin-right: 12px;
            transform: translate(0px, -1px);
            display: inline-block;
          }
        }
        &.list{
          .nav-link{
            position: relative;
            &:before{
              background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
              content: "";
              width: 14px;
              height: 14px;
              position: absolute;
              right: 20px;
              top: 16px;
            }
          }
        }
        .sub-nav{
          list-style: none;
          padding: 0 15px 10px 56px;
          display:none;
          li{
            a{
              font-weight: $font-weight-medium;
              font-size: rem(13);
              color: #000;
              @extend .inlineblock;
              &:hover{
                text-decoration: none;
              }
              &:before{
                content: "-";
                margin-right: 5px;
              }
            }
          }
        }
        &.active{
          background-color: #f2f4f4;
          .sub-nav{
            display:block;
          }
          .nav-link{
            i{
              color: $primary;
              &.icon-materials{
                color: $primary;
              }
              &.icon-work{
                color: $yellow;
              }
              &.icon-request{
                color: $red;
              }
              &.icon-offce-details{
                color: $green;
              }
            }
          }
        }
      }
    }
  }
  .page-content{
    background: #f2f4f4;
    border-radius: 6px 0 0 0;
    padding: 25px 0 25px 10px;
    height: 100%;
    transition: transform 0.3s;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #7b7b7b #e2e2e2;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #e2e2e2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #7b7b7b;
    }


    .container{
      margin:0;
      width:100%;
      min-height:100%;
      max-width:1366px;
    }
    address{
      margin-bottom: 15px;
      p{
        font-weight: $font-weight-medium;
        line-height: 1.6;
      }
    }
    .dropdown{
      .dropdown-toggle{
        &:after{
          border-top: 0.4em solid;
          transform: translate(0px, 2px);
        }
        &.btn{
          &.btn-icon{
            padding: 1px 6px;
            line-height: 1;
          }
        }
        @extend %user-select-none;
        &.no-arrow:after{
          display: none;
        }
      }
      .dropdown-menu{
        padding: 10px 20px;
        box-shadow: 0 0 14px -2px rgba(0, 0, 0, 0.1);
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        min-width: 170px;
        .dropdown-item{
          font-size: rem(14);
          padding: 0;
          color:#333;
          margin-bottom: 2px;
          font-weight: $font-weight-medium;

          &:last-child {
            margin-bottom:0;
          }
          &:hover,
          &:focus{
            color:#333;
            background-color: transparent;
          }
        }
      }
      &.dropdown-status{
        .dropdown-toggle{
          &.btn b{
            font-weight: $font-weight-bold;
          }
        }
        .dropdown-menu{
          padding: 1px 0 3px;
          .alert{
            padding: 4px 10px;
            color: #333;
            margin-bottom: 5px;
            font-weight: $font-weight-medium;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            font-size: rem(14);
            &:hover{
              box-shadow: 0 0 0 30px inset rgba(200,200,200,0.2);
            }
          }
        }
      }
    }
    .camera{
      .video-box{
        background: #d6d6d6;
        margin-right: 30px;
        position: relative;
        width: 320px;
        display: flex;
        video{
          width: 100%;
          height: auto;
        }
      }
      #canvas{
        display: none;
      }
      .output{
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        margin: 0 -8px;

        .item{
          flex: 0 0 33.33%;
          position: relative;
          padding: 0 8px;
          margin-bottom: 14px;

          .remove-image{
            position: absolute;
            right: 4px;
            background: #fff;
            width: 20px;
            border-radius: 30px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(30%, -30%) rotate(45deg);
            font-size: 12px;
            box-shadow: 0 0 10px rgba(0,0,0,0.4);
          }
          img{
            width: 100%;
          }
        }
      }
    }
    .card{
      border: none;
      border-radius: 10px;
      margin-bottom: 20px;
      &.full-height{
        height: calc(100% - 20px);
      }
      .card-header{
        padding: 25px 36px 0;
        border: none;
        background: transparent;
        display: flex;
        justify-content: space-between;
        h2{
          margin: 0;
        }
        .btn-group{
          .btn{
            margin-left: 12px;
            padding: 8px 24px;
            font-weight: $font-weight-medium;
          }
        }
      }
      .card-body{
        padding: 25px 30px;
        //.collapse{
        //  &>p{
        //    margin: 3px 0 0;
        //    font-weight: 500;
        //    font-size: 14px;
        //  }
        //}
      }


      &.db-card{
        padding: 22px 22px 12px;
        border-radius: 10px;
        margin-bottom: 30px;
        height: calc(100% - 30px);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
        .card-header{
          padding: 0;
          display: inline-block;
          h4{
            margin: 0;
            font-size: rem(17);
            i{
              font-size: rem(30);
              vertical-align: middle;
              margin-right: 15px;
              transform: translate(0px, -2px);
              display: inline-block;
              color: $primary;
              &.icon-materials{
                color: $primary;
              }
              &.icon-work{
                color: $yellow;
              }
              &.icon-request{
                color: $red;
              }
              &.icon-offce-details{
                color: $green;
              }
            }
          }
          .badge{
            float: right;
            font-weight: $font-weight-medium;
            border-radius: 50px;
            min-width: 30px;
            height: 30px;
            border: 1px solid #bdbdbd;
            padding: 8px 7px;
            background: transparent;
          }
        }
        .card-body{
          padding: 0;
          margin: 15px 0 10px;

          ul{
            padding: 0;
            margin: 0 0 0 15px;
            list-style: none;
            border-left: 2px solid #eaeaea;
            padding-left: 28px;
            li{
              margin: 0 0 7px;
              color: #7d7d7d;
              font-weight: $font-weight-medium;
              &:last-child{
                margin: 0;
              }
              a{
                color: #000;
              }
              .badge-light{
                font-size: 100%;
                float: right;
                padding: 4px 0;
                font-weight: $font-weight-medium;
                background: transparent;
                color: #7d7d7d;
              }
            }
          }

          p.num {
            font-size: 25px;
            margin:0;
            color:#fff;
          }
        }
        .card-footer{
          padding: 11px 0 0;
          text-align: right;
          background: transparent;
          border-color: rgba(255,255,255,0.2);
          a{
            color: #fff;
          }
        }
      }
      &.project-card{
        border-radius: 0 0 7px 7px;
        margin-bottom: 30px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        .card-header{
          padding: 15px 25px;
          background: #f2f4f4;
          h4{
            margin: 0;
            font-weight: $font-weight-medium;
            font-size: rem(14);
          }
        }
        .card-body{
          padding: 18px 25px;
          background: #e7e9ea;
          p{
            font-size: rem(14);
            color: #3a4048;
            margin: 0 0 2px;
            &.date{
              font-size: rem(13);
              color: #6c727a;
              margin: 0;
            }
          }
        }
      }
    }
    .projectpage{
      display: flex;
      flex-wrap: wrap;
      .project-details{
        flex-grow: 1;
        flex-basis: 0;
        width: 100%;
        max-width: calc(100% - 440px);
      }
      .project-rightbar{
        flex-grow: 0;
        flex-basis: 420px;
        background: #f8f8f8;
        margin-left: 20px;
        padding: 25px;
        .btn{
          border-radius: 3px;
          &.btn-gray{
            margin-right: 8px;
          }
          &.btn-icon{
            padding: 3px 8px;
            i{
              font-size: rem(18);
              line-height: 1.4;
            }
          }
        }
        .form-group{
          label{
            margin: 0 0 10px;
          }
          .form-control{
            background: #e6e8e8;
          }


          textarea.form-control{
            height: 110px;
          }
          .progress{
            height: rem(14);
            font-size: rem(13);
            border-radius: 10px;
            .progress-bar{
              padding: 5px 0;
              color: #333;
              font-weight: $font-weight-medium;
              &.bg-light{
                background: #cfcfcf !important;
              }
            }
          }
          .profile{
            font-size: rem(14);
            margin-bottom: 9px;
            font-weight: $font-weight-medium;
            margin-left: 10px;
            img{
              width: 24px;
              margin-right: 3px;
              vertical-align: bottom;
            }
          }
          .badge{
            font-size: rem(12);
            border-radius: 40px;
            padding: 6px 10px;
            margin-right: 5px;
            &.badge-light{
              background: #e6e8e8;
            }
          }
          .priority-list{
            padding: 0;
            list-style: none;
            li{
              margin: 0 0 8px;
              font-weight: $font-weight-medium;
              font-size: rem(14);
              i{
                margin-right: 9px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .form-check{
      padding: 0;
      margin: 4px 0;
      &.form-check-inline{
        margin-right: rem(12);
      }
      .form-check-label{
        cursor: pointer;
        margin: 0 !important;
        padding-left: 26px;

        font-weight: $font-weight-medium;
        font-size: rem(15);
        @extend %user-select-none;

        &:before{
          content: "";
          height: 17px;
          width: 17px;
          border: 1px solid #c2c2c2;
          background-color: #fff;
          box-shadow: 0 0 0 0 #b4c2ce inset;
          position: absolute;
          left: 0;
          top: 3px;
          @extend .transition;
        }
        small{
          font-size: 90%;
        }
        b{
          font-weight: $font-weight-medium;
        }
        .light{
          color: #898989;
          margin-top: 3px;
          @extend .inlineblock;
        }
      }
      .form-check-input{
        position: absolute;
        opacity: 0;
        left:0;
        margin:0;
        &[type="checkbox"] ~ .form-check-label:before{
          border-radius: 3px;
        }
        &[type="radio"] ~ .form-check-label:before{
          border-radius: 20px;
        }
        &:checked ~ .form-check-label:before{
          box-shadow: 0 0 0 8px $primary inset;
        }
      }
    }
    .form-group{
      .tablist{
        margin: 10px 0 0;
        .nav-item{
          margin-bottom: 0;
          .nav-link{
            padding: 9px 40px;
            margin-right: 12px;
            border: 1px solid #eaeaea;
            font-weight: $font-weight-normal;
          }
        }
      }

      .form-detail{
        border: 1px solid #e7e7e7;
        padding: 10px 15px;
        p{
          font-weight: $font-weight-medium;
          margin: 0;
          padding: 4px 0;
        }
      }

      .saprator{
        margin: 0;
        height: 38px;
        vertical-align: middle;
        display: table-cell;
        font-weight: $font-weight-medium;
      }
      label{
        color: #000;
        font-size: rem(14);
        font-weight: $font-weight-medium;
        width: 100%;
        a{
          &:hover{
          }
        }
        &.d-flex{
          align-items: center;
          margin:0;
          font-size: rem(15);
          .form-control{
            margin-left: 12px;
          }
        }
      }
      .form-control{
        height: 40px;
        border: 1px solid #d0d0d0;
        font-size: rem(14);
        border-radius: 0;
        padding: 0 12px;
        @extend .transitionBorder;


        &:focus{
          box-shadow: 0 0 0;
          border-color: $primary;
        }
        &:disabled,&[disabled]{
          background-color: #f7f7f7;
        }
        &[readonly]{
          background-color: transparent;
          border-color: transparent;
          opacity: 0.5;
        }
      }



      .twitter-typeahead{
        width: 100%;
        input,pre{
          text-transform: uppercase;
        }
        .tt-menu{
          background: #fff;
          width: 100%;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);

          .tt-dataset{
            border-left: 1px solid #0790c9;
            border-right: 1px solid #0790c9;
            .tt-suggestion{
              padding: 6px 12px;
              cursor: pointer;
              border-bottom: 1px solid #0790c9;
              text-transform: uppercase;
            }
            .tt-selectable{
              coR:e;
            }
          }
        }
      }

      .help-block{
        font-size: 12px;
        margin-top: 4px;
        font-weight: 500;
      }

      &.has-error .help-block{
        color:$danger;
      }
      &.has-success .help-block{
        color:$success;
      }

      select.form-control{
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #fff url(../images/select-icon.png) no-repeat scroll right center;
        padding-right: 30px;
      }
      textarea.form-control{
        height: 128px;
        padding: 10px 12px;
      }
      .file-select{
        position: relative;
        margin-bottom: 12px;
        .text-muted{
          color: #000 !important;
          opacity: 0.9;
        }
        .selected-img{
          display: inline-block;
          position: relative;
          z-index: 999;
          span{
            margin-left: 10px;
            font-weight: $font-weight-semi-bold;
            font-size: rem(13);
            cursor: pointer;
            @extend %user-select-none;
          }
          img{
            max-width: 100%;
            max-height: 50px;
            object-fit: contain;
            padding: 4px;
            border: 1px solid #dadada;
          }
        }
        input{
          position: absolute;
          opacity: 0;
          top: 11px;
          width: 100%;
        }
        label{
          width: 100%;
          margin: 0;
          background: #fcfcfc;
          border: 1px solid #eaeaea;
          text-align: center;
          padding: 20px 10px;
          cursor: pointer;
          a{
            color:#12659f;
          }
          img{
            margin: 0 0 5px;
          }
          .status{
            display: inline-block;
            width: 100%;
            font-size: rem(12);
          }
        }
        &.inline{
          label{
            text-align: center;
            .status{
              width: initial;
              margin-left: 12px;
              font-size: rem(14);
              color: #167efb;
            }
          }
        }
        &.attachment{
          label{
            text-align: left;
            padding: 12px 50px;
            background: #fff;
            .icon-attachment{
              font-size:rem(20);
              position: absolute;
              left: 15px;
              top: 13px;
              color: #6c6c6c;
              opacity: 0.6;
            }
            .filename{
              color: #6c6c6c;
              opacity: 0.6;
              font-size: rem(15);
              font-weight: $font-weight-semi-bold;
              &.active{
                opacity: 1;
              }
            }
            .clear{
              position: absolute;
              right: 14px;
              top: 7px;
              font-size: rem(32);
              line-height: 1;
              transform: rotate(45deg);
              opacity: 0.6;
              display: none;
              font-weight: $font-weight-normal;
              @extend .transition;
              &:hover{
                opacity: 1;
              }
            }
          }
        }
      }
      .flex-group{
        display: flex;
        .form-control{
          flex-grow: 1;
        }
        .btn{
          margin-left:20px;
          padding: 9px 22px;
        }
      }
      .input-group{
        position: relative;
        &.d-flex{
          select{
          }
        }
        .input-group-prepend,
        .input-group-append{
          background: #fff;
          margin:0;
          border:1px solid #e0e1e1;
          z-index: 11;
          padding: 0 9px;
          @extend .transitionBorder;
          .input-group-text{
            background: transparent;
            color: #000;
            padding: 0;
            font-weight: $font-weight-medium;
            font-size: rem(15);
            border-radius: 0;
            border:none;
          }
        }
        .input-group-prepend{
          order: -1;
          border-right:none;
          margin-right: -1px;
          padding-right: 0;
        }
        .input-group-append{
          order: 1;
          border-left:none;
          margin-left: -1px;
          padding-left: 0;
        }
        .form-control:focus{
          &+.input-group-prepend,
          &+.input-group-append{
            border-color: $primary;
          }
        }
        .form-control:disabled{
          &+.input-group-prepend,
          &+.input-group-append{
            background-color: #f7f7f7;
          }
        }
        .form-control[readonly]{
          &+.input-group-prepend,
          &+.input-group-append{
            background-color: transparent;
            border-color: transparent;
            opacity: 0.5;
          }
        }
      }
      .plan-list{
        border: 1px solid #e7e7e7;
        padding: 20px;
        padding-bottom: 60px;
        min-height: 495px;
        position: relative;
        .add-plan{
          width: 100%;
          height: 100px;
          color: $primary;
          font-weight: $font-weight-normal;
          margin-bottom: 15px;
          padding: 0 20px;
          font-size: rem(14);
        }
        h3{
          position: relative;
          margin: 0 0 15px;
          .edit-plan{
            position: absolute;
            right:0;
            top:0;
            cursor: pointer;
          }
        }

        ul{
          padding: 0;
          margin: 0 0 20px;
          li{
            list-style: none;
            font-size: rem(13);
            font-weight: $font-weight-medium;
            margin: 0 0 8px;
          }
        }
        .total{
          position: absolute;
          bottom: 0;
          background: #e9eaea;
          left: 0;
          right: 0;
          padding: 10px 20px;
          font-size: rem(15);
          font-weight: $font-weight-medium;
        }
      }
    }
    .filter{
      margin-bottom: -15px;
      max-width: 1020px;
      @extend .inlineblock;
      &.gray{
        background: #f1f1f1;
        padding: 15px 30px 0;
        max-width: 100%;
        margin-bottom: 0;
      }
      //.form-group{
      //  display: flex;
      //  align-items: center;
      //  label{
      //    white-space: nowrap;
      //    margin-right: 13px;
      //    margin-bottom: 0;
      //    width: initial;
      //
      //  }
      //}
    }
    .search-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 -10px;
      .search{
        max-width: 470px;
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        .form-group{
          position: relative;
          margin: 0;
          .form-control{
            background: #e6e8e8;
            border-radius: 6px;
            border: none;
            padding-right: 40px;
          }
          button{
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            font-size: rem(20);
            padding: 9px 13px;
            background: transparent;
            line-height: 1;
            opacity: 0.5;
          }
        }
      }
      .search-filter{
        margin-bottom: 20px;
        padding: 0 10px;
        .form-group{
          margin: 0;
          .form-control{
            background-color: #e6e8e8;
            border-radius: 6px;
            border: none;
            min-width: 120px;
          }
        }
      }
    }
    .list-striped{
      padding: 0;
      list-style: none;
      li{
        font-size: rem(13);
        padding: 4px 10px;
        &:nth-child(2n-1){
          background: #f1f1f1;
        }
      }
    }

    .profile{
      display: table;
      margin: -1px 0;
      &:hover{
        text-decoration: none;
      }
      img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: contain;
        margin: -3px 10px -3px 0;
      }
      span{
        color: $blue;
        font-size: rem(14);
        font-weight: $font-weight-medium;
      }
      &.flex{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-width: 240px;
        >*{
          flex-grow: 1;
          flex-basis: 0;
        }
        img{
          width: 45px;
          height: 45px;
          margin-right: 15px;
          flex: 0 0 45px;
        }
        .content{
          white-space: initial;
          h4{
            margin: 0;
          }
          p{
            font-size: rem(13);
            color: #767676;
            margin: 0;
          }
        }
      }
    }
    .summary{
      font-size: 13px;
      margin-bottom: 10px;
    }
    .scroller{
      max-height: 500px;
      padding-right: 35px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #e2e2e2;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7b7b7b;
      }
      .table{
        thead{
          tr{
            th{
              position: sticky;
              top: 0;
              background: #fff;
              box-shadow: 0 -4px 0 3px #fff;
              z-index: 9;
            }
          }
        }
      }
    }
    .table{
      tbody{
        box-shadow: 0 0 0 1px #e3e3e3 inset;
      }
      thead{
        tr{
          td{
            border-bottom: 0;
            .form-control{
              padding: 5px 10px;
              height: initial;
              font-size: 13px;
            }
            .help-block{
              font-size: 11px;
              margin-top: 4px;
              font-weight: 500;
            }
            &.has-error{
              .help-block{
                color:$danger;
              }
            }
          }
          th{
            border-color: #fff;
            border-bottom: 1px solid #e3e3e3;
            font-weight: $font-weight-medium;
            color: #9a9a9a;
            padding: 10px 15px;
            white-space: nowrap;
            font-size: rem(15);
            .form-check{
              margin:0;
            }
            a{
              color: #9a9a9a;
              &[data-sort]{
                background-size: 11px;
                background-repeat: no-repeat;
                background-position: right 4px center;
                padding-right: 20px;
                &.desc{
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 19' fill='%239a9a9a'%3e%3cpath d='M 16 10 L 8.5 18 L 1 10 l 1.8 -1.4 l 2.8 3 c 0.3 0.2 0.6 0.3 1 0.3 c 0.3 0 0.6 -0.1 0.8 -0.3 V 1 h 2.4 l 0 10.7 c 0.2 0.2 0.5 0.3 0.7 0.3 c 0.3 0 0.7 -0.1 0.9 -0.3 l 2.9 -3 L 16 10 Z'/%3e%3c/svg%3e");
                }
                &.asc{
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 19' fill='%239a9a9a'%3e%3cpath d='M 1 8.9 L 8.5 1 L 16 8.9 l -1.8 1.4 l -2.8 -3 c -0.3 -0.2 -0.6 -0.3 -1 -0.3 c -0.3 0 -0.6 0.1 -0.8 0.3 V 18 H 7.3 l 0 -10.7 C 7.1 7.1 6.8 7 6.6 7 C 6.2 7 5.9 7.1 5.6 7.3 l -2.9 3 L 1 8.9 Z'/%3e%3c/svg%3e");
                }
                &:hover{
                  color:#333;
                }
              }
            }
          }
          &.filters{
            box-shadow: 0 0 0 1px #e3e3e3 inset;
          }
          td {
            padding: 10px 15px;
          }
        }
      }
      tbody{
        //box-shadow: 0 0 0 1px #e3e3e3 inset;
        tr{
          td{
            padding: 10px 15px;
            color: #000000;
            font-size: rem(15);
            white-space: nowrap;
            border-color: #e3e3e3;

            &.wrap{
              white-space: normal;
              min-width: 300px;
              max-width: 400px;
            }
            .form-check{
              margin:0;
            }
            .badge{
              font-size: rem(12);
              font-weight: $font-weight-normal;
              padding: 7px 12px;
              border-radius: 4px;
              margin: -3px 0;
              &.badge-pill{
                display: inline-block;
                border-radius: 10rem;
                padding: 6px;
                vertical-align: middle;
                margin-right: 10px;
                transform: translate(0px, -1px);
                position: relative;
                &:before{
                  content: "";
                  position: absolute;
                  left: 50%;
                  height: 0;
                  padding: 3px;
                  background: #fff;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 10px;
                }
              }
            }
            .btn.btn-sm{
              font-size: rem(12);
              font-weight: $font-weight-medium;
              margin: -2px 0;
            }
            &.action{
              text-align: right;
              width: 60px;
              a{
                cursor: pointer;
              }
            }
            .table{
              margin: -1px;
              width: -webkit-fill-available;
              border: none;
            }
          }
        }
      }
      tfoot{
        td{
          a{
            color:#909090;
            &:hover{
              text-decoration: none;
              color:#000;
            }
          }
        }
      }
      &.table-bordered{
        tbody{
          box-shadow: 0 0 0;
        }
      }
      &.table-striped{

      }
      &.table-noborder{
        border-color: transparent;
        tbody{
          box-shadow: 0 0 0;
          tr{
            td{
              border-color: transparent;
              padding: 12px 20px 12px 0;
              strong{
                color: #666;
              }
            }
          }
        }
      }
      &.table-sm{
        thead{
          tr{
            th{
              font-size: rem(13);
              padding: 7px 15px;
            }
          }
        }
        tbody{
          tr{
            td{
              font-size: rem(13);
              padding: 7px 15px;
            }
          }
        }
      }
    }
    .slider{
      margin-top: 10px;
      margin-bottom: 30px;
      position: relative;
      width: 100%;
      max-width: 360px;
      .item{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .owl-nav{
        margin: 0;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 0;
        button{
          background: rgba(0,0,0,0.3);
          width: 40px;
          height: 80px;
          transform: translate(0, -50%);
          margin: 0;
          transition: all 0.3s;
          &:hover{
            background: rgba(0,0,0,0.5);
          }
          &.owl-prev{
            float: left;
          }
          &.owl-next{
            float: right;
          }
          span{
            font-size: rem(65);
            line-height: 0.7;
            color: #fff;
            font-weight: $font-weight-light;
          }
        }
      }
    }
    .details-content{
      table.info{
        tr{
          th,td{
            font-size: rem(14);
            line-height: 1.4;
            padding: 8px 0;
          }
          th{
            font-weight: $font-weight-medium;
            padding-right:24px;
            white-space: nowrap;
          }
          td{
            font-weight: $font-weight-semi-bold;
            .badge{
              font-weight: $font-weight-semi-bold;
              padding: 7px 12px;
            }
          }
        }
      }
      .profile{
        margin-top: 10px;
        margin-bottom: 8px;
        img{
          width: 42px;
          height: 42px;
          vertical-align: top;
        }
        span{
          font-weight: $font-weight-semi-bold;
          font-size: rem(15);
          display: inline-block;
          small{
            color: #555555;
            font-weight: 500;
            display: inline-block;
            transform: translate(0px, -7px);
            font-size: 75%;
          }
        }
      }
      .image-gallery{
        display: flex;
        flex-wrap: wrap;
        .item{
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          img{
            width: 50px;
            height: 50px;
            object-fit: cover;
          }
        }
      }
    }
    .profile-page{
      max-width: 930px;
      margin-bottom: 30px;
      .profile{
        margin: 0;
        width: 130px;
        img{
          width: 130px;
          height: 130px;
          border-radius: 5px;
          margin:0;
        }
      }
      h4{
        margin: 0 0 8px;
      }
      table.info{
        tr{
          th,td{
            font-size: rem(14);
            line-height: 1.3;
            padding: 5px 0;
          }
          th{
            font-weight: $font-weight-medium;
            padding-right:30px;
            white-space: nowrap;
          }
          td{
            font-weight: $font-weight-semi-bold;
            .badge{
              font-weight: $font-weight-semi-bold;
              padding: 7px 12px;
            }
          }
        }
      }
    }

    .feeds{
      max-width: 850px;
      .item{
        padding: 24px;
        background: #fbfbfb;
        box-shadow: 0 0 0;
        border-bottom: 1px solid #e1e1e1;
        position: relative;
        padding-right: 74px;
        @extend .inlineblock;
        &:last-child{
          border-bottom: none;
        }
        &:hover{
          box-shadow: 0 2px 8px #00000011;
          z-index: 2;
        }
        h3{
          font-size: rem(16);
          max-width: 470px;
          margin: 0 0 8px;
        }
        p{
          max-width: 470px;
          font-size: rem(14);
          color: #000;
        }
        .badge{
          font-size: rem(12);
          font-weight: $font-weight-normal;
          padding: 6px 9px;
          margin-right: 3px;
          border-radius: 0;
          margin-bottom: 7px;
          &.badge-secondary{
            color: #fff;
            background: #bcbcbc;
          }
        }
        ul{
          padding: 0;
          margin: 0;
          display: flex;
          list-style: none;
          justify-content: space-between;
          flex-wrap: nowrap;
          max-width: 270px;
          li{
            display: inline-block;
            span{
              color: #000;
              font-size: rem(15);
              font-weight: $font-weight-medium;
              margin-bottom: 5px;
              display: inherit;
              &.cl-light{
                display: grid;
                opacity: 0.7;
                text-transform: uppercase;
                font-size: rem(13);
              }
              &:last-child{
                margin:0;
              }
            }
          }
        }
        .add-favorites{
          position: absolute;
          right: 22px;
          top: 22px;
          width: 30px;
          height: 30px;
          border: 1px solid;
          text-align: center;
          padding: 5px 0;
          color: #c6c6c6;
          cursor: pointer;
          border-radius: 3px;
          @extend .transition;
          .icon-heart{
            color:$red;
          }
          &:hover{
            color: #777;
          }
        }
      }
    }
    .modal-dialog{
      margin: 17px auto;
      padding: 0 16px;
      .modal-dialog-centered{
        min-height: calc(100vh - 34px);
      }
      .modal-content{
        border-radius: 15px;
        border: none;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.2);
        .modal-header{
          padding: 30px 30px 0;
          border: none;
          .modal-title{
            font-size: rem(18);
            font-weight: $font-weight-semi-bold;
          }
          .close{
            font-size: rem(38);
            font-weight: $font-weight-medium;
            padding: 4px 7px;
          }
        }
        .modal-body{
          padding: 20px 30px 30px;
          .scroller{
            padding-right: 10px;
            height: 400px;
            margin-right: -18px;
          }
          .table{
            thead tr th,
            tbody tr td{
              .remove-row{
                font-size: rem(28);
                line-height: 0.7;
                vertical-align: middle;
                margin-right: 8px;
                transform: translate(0px, -1px);
                display: inline-block;
                opacity: 0;
                visibility: hidden;
                cursor: pointer;
                @extend .transition;
                @extend %user-select-none;
              }
            }
            thead{
              tr{
                th{
                  background: #e2e2e2;
                  border-color: #e2e2e2;
                  font-weight: $font-weight-medium;
                  color: #444;
                  padding: 7px 15px;
                  font-size: rem(13);
                  box-shadow: 0 -2px 0 1px #e2e2e2;
                }
              }
            }
            tbody{
              tr{
                td{

                }
                &:hover{
                  td{
                    .remove-row{
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.modal-visible{
        margin: 0;
        padding:0;
        .modal-content{
          border-radius: 10px;
          box-shadow: 0 0 15px rgba(0,0,0,0.14);
          border:none;
          .modal-body{
            padding: 40px;
          }
        }
      }
      &.preview-modal{
        width: 100%;
        max-width: 1240px;
        .modal-content{
          border-radius: 0;
          .modal-header{
            padding: 15px 20px 0;
          }
          .modal-body{
            padding: 0 40px 40px;
          }
        }
      }
    }
    .hr{
      background: #cfcfcf;
      height: 1px;
      &.mg-30{
        margin: 40px 0;
      }
      &.mg-20{
        margin: 20px 0;
      }
    }
  }
}
.pdf-section{
  .pdf-header{
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 2px solid #bfbfbf;
    .logo{
      margin-bottom: 40px;
      img{
        width: 300px;
      }
    }
    h2{
      font-size: rem(26);
      opacity: 0.3;
      margin-top: -60px;
    }
    p{
      font-size: rem(16);
      line-height: 1.4;
      color: #333;
    }
    h4{
    }
    address{
      font-weight: $font-weight-medium;
      color: #000;
      padding: 14px 0;
      line-height: 1.4;
    }
  }
  .pdf-content{
    h2{
      font-size: rem(22);
    }
    h5{
      margin: 0 0 14px;
      font-size: rem(14);
    }
    h4{
      margin: 0 0 11px;
      font-size: rem(16);
    }
    h3{
    }
    p{
      color: #000;
      font-weight: $font-weight-medium;
    }
    .table tr td{
      padding: 7px 16px !important;
      font-size: rem(15) !important;
      font-weight: $font-weight-medium;
    }
    .table.small tr td{
      font-size: rem(13) !important;
    }
  }
  .pdf-footer{
    border-top: 2px solid #bfbfbf;
    padding-top: 12px;
    p{
      margin: 0;
      font-weight: $font-weight-medium;
      color: #9d9d9d;
    }
  }
}
.sidebar-toggle{
  display: none;
}

.d-xl-none{
  display: block !important;
}

.modal-backdrop{
  background: rgba(255,255,255,1);
}








// less than 1920px
@include media-breakpoint-down-destop(xxxl) {

}
// less than 1600px
@include media-breakpoint-down-destop(xxl) {
  .main-content .page-content .projectpage{
    .project-details {
      max-width: calc(100% - 380px);
    }
    .project-rightbar {
      flex-basis: 360px;
    }
  }
}
// less than 1366px
@include media-breakpoint-down-destop(xl) {

  .main-content{
    position: relative;
    &:before{
      content: "";
      background: #00000094;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
    .sidebar {
      margin-left: -210px;
    }
    .page-content {
      border-radius: 0;
      position: relative;
    }
    &.show-sidebar{
      &:before{
        opacity: 1;
        visibility: visible;
      }
      .sidebar {
        transform: translate(210px, 0px);
      }
      .page-content {
        transform: translate(100px, 0px);
        overflow: hidden;
      }
    }
  }

  .breadcrumb {
    padding: 10px 70px;
  }
  .sidebar-toggle{
    display: block;
    width: 27px;
    height: 16px;
    position: fixed;
    left: 30px;
    top: 96px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      height: 2px;
      width: 100%;
      background: black;
      transform: translate(0,-50%);
    }
  }
}

// less than 1200px
@include media-breakpoint-down(lg) {
  .main-content .page-content .projectpage {
    position: relative;
    .project-details {
      max-width: 100%;
    }
    .project-rightbar {
      position: absolute;
      right: 0;
      top: 0;
      width: 360px;
      height: 100%;
      overflow: scroll;
      box-shadow: -3px 0 12px rgba(0,0,0,0.2);
      display: none;
    }
  }
}
// less than 992px

@include media-breakpoint-down(md) {
  .main-content .page-content .slider {
    max-width: 310px;
  }
  .main-content .page-content .card .card-header{
    padding: 25px 30px 0;
  }
  .main-content .page-content .card .card-body {
    padding: 25px 30px;
  }
  .tablist .nav-item {
    margin-bottom: 20px;
  }
  .modal-dialog {
    max-width: 100%;
  }
  .tablist .nav-item .nav-link {
    margin-right: 20px;
  }
}
// less than 768px
@include media-breakpoint-down(sm) {
  .main-content .page-content .slider {
    max-width: 360px;
  }
  .sidebar-toggle {
    top: 78px;
  }
  .pdf-section .pdf-header h2 {
    margin-top: 0;
  }
  .main-content .page-content .modal-dialog.preview-modal .modal-content .modal-body {
    padding: 0 30px 30px;
  }
}
// less than 576px
@include media-breakpoint-down(xs) {
  .breadcrumb {
    padding: 10px 60px;
  }
  .sidebar-toggle {
    left: 20px;
  }
  .header .right-buttons{
    .dropdown{
      margin-left: 22px;
      .dropdown-toggle{
        &:after{
          display: none;
        }
        .avatar{
          margin: 0;
        }
        span{
          display: none;
        }
      }
    }
  }
  .main-content .page-content {
    padding: 30px 5px;
    .card .card-header{
      padding: 20px 20px 0;
    }
    .card .card-body {
      padding: 20px;
    }
    .feeds .item {
      padding: 14px;
      padding-right: 54px;
      .add-favorites {
        right: 12px;
        top: 12px;
      }
    }
    .scroller {
      padding-right: 20px;
    }
    .table-responsive{
      .table{
        thead{
          display: none;
        }
        tbody tr{
          padding: 9px 0;
          display: inline-block;
          border-bottom: 1px solid #f2f4f4;
          position: relative;
          width: 100%;
          &:last-child{
            border-bottom: none;
          }
          td{
            display: inline-block;
            width: 100%;
            padding: 5px 32px 5px 13px;
            white-space: normal;
            border: none;
            &:before {
              content: attr(data-label);
              float: left;
              margin-right: 8px;
              font-weight: $font-weight-medium;
              color: #9a9a9a;
            }
            &.action{
              width: initial;
              padding: 0;
              position: absolute;
              right: 11px;
              top: 15px;
            }
          }
        }
      }
    }
  }
  .main-content .page-content .modal-dialog.modal-visible .modal-content .modal-body {
    padding: 20px;
  }
  .d-xl-none{
    display: none !important;
  }
  .main-content .page-content .form-group .plan-list {
    padding: 15px 15px 30px;
  }
  .pdf-section .pdf-header .logo img {
    width: 240px;
  }
  .main-content .page-content .modal-dialog.preview-modal .modal-content .modal-body {
    padding: 0 15px 15px;
  }
}
.select2{
  &-container &-selection--single{
    height: 42px !important;
    padding: 6px 3px;
    border: 1px solid #d0d0d0 !important;

    .select2-selection__arrow {
      height: 40px !important;
    }
  }
}

@media print {
  .container {
    width: auto;
  }
}
